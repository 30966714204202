import React, { useRef, useState } from "react";
import Slider from "react-slick";
import classnames from "classnames/bind";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { ICON_ARROW_LEFT } from "@syl-codes/ui/svgs";

import { groupBy } from "@syl-codes/modules";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { fetchMilestone } from "@/services/notion";
import { Milestone } from "@/types/notion/milestone";
import styles from "./index.module.scss";
import { Quarter } from "./quarter";

const cx = classnames.bind(styles);

export const MilestoneSection = () => {
  const isMobile = useIsMobile();
  const slideRef = useRef<Slider>(null);
  const { t } = useTranslation("home");
  const [index, setIndex] = useState(0);
  const router = useRouter();
  const { data, isLoading, error } = useQuery("milestone", () => fetchMilestone(router.locale));

  if (!data || isLoading || error) return null;

  const milestones: [string, Milestone[]][] = Object.entries(
    groupBy(data, (item: Milestone) => item.properties.quarter.select.name),
  );
  const handleClickPrev = () => {
    slideRef.current?.slickPrev();
  };
  const handleClickNext = () => {
    slideRef.current?.slickNext();
  };

  const beforeChange = (_prev: number, next: number) => {
    setIndex(next);
  };

  return (
    <div className={cx("milestone")} id="milestone">
      <div className={cx("title")}>
        <span className={cx("bar")}>|</span> MILESTONE <span className={cx("bar")}>|</span>
      </div>
      <div>
        <div>
          {index > 0 && (
            <a className={cx("button", "prev")} onClick={handleClickPrev}>
              {isMobile ? "" : "PREV"}
              <ICON_ARROW_LEFT />
            </a>
          )}
          {index < (isMobile ? milestones.length - 1 : Math.floor(milestones.length / 3)) && (
            <a className={cx("button", "next")} onClick={handleClickNext}>
              {isMobile ? "" : "NEXT"}
              <ICON_ARROW_LEFT />
            </a>
          )}
        </div>
        <Slider
          {...{
            className: cx("milestoneContainer"),
            infinite: false,
            slidesToShow: isMobile ? 1 : 3,
            ref: slideRef,
            arrows: false,
            beforeChange: beforeChange,
          }}
        >
          {milestones.map(([title, items]) => (
            <Quarter key={title} title={title} items={items} />
          ))}
        </Slider>
        <div className={cx("milestoneLine")}></div>
        <div className={cx("info")}>* {t("milestone-info")}</div>
      </div>
      <div className={cx("milestoneBackground")} />
    </div>
  );
};
