import React from "react";
import classnames from "classnames/bind";
import Image from "next/image";
import { useTranslation } from "next-i18next";

import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import styles from "./index.module.scss";
import { News } from "./news";

const cx = classnames.bind(styles);

export const LogoSection = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");

  return (
    <>
      {!isMobile && (
        <div className={cx("title")}>
          <span className={cx("bar")}>|</span> P2E TRADING NFT CARD GAME <span className={cx("bar")}>|</span>
        </div>
      )}
      <div className={cx("logo")}>
        <div className={cx("main_logo_img")}>
          <Image quality={100} priority src="/syltare_main_logo.png" layout="fill" />
        </div>
        {isMobile && (
          <div className={cx("title")}>
            <span className={cx("bar")}>|</span> P2E TRADING NFT CARD GAME <span className={cx("bar")}>|</span>
          </div>
        )}
        <News />
      </div>
      <p className={cx("description")}>{t("logo-description")}</p>
    </>
  );
};
