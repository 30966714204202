import { ethers } from "ethers";
import { TFunction, Namespace } from "react-i18next";
import { JsonRpcSigner } from "@ethersproject/providers";
import { CHAIN_ID_HEX } from "@syl-codes/modules/contracts";

import { ExternalProvider } from "@ethersproject/providers";
import { MetaMaskInpageProvider } from "@metamask/providers";

import { hexToNumber } from "../../number/number.utils";

type Provider = ExternalProvider & MetaMaskInpageProvider;

// declare global types
// like window["klaytn"]
declare global {
  interface Window {
    ethereum: Provider | undefined;
  }
}

const ethereum = typeof window !== "undefined" ? window.ethereum : undefined;
export const WEB3_PROVIDER = ethereum ? new ethers.providers.Web3Provider(ethereum, "any") : undefined;

export interface MetamaskError {
  code: number;
  message: string;
}

export const convertMetamaskError = (metamaskError: unknown) => {
  const error = metamaskError as MetamaskError;
  if (
    error.code === 4001 ||
    error.message.toLowerCase().includes("user denied") ||
    error.message.toLowerCase().includes("user rejected")
  ) {
    return "UserRejectedError";
  }
  if (error.code === 4902 || error.message.toLowerCase().includes("unrecognized chain id")) {
    return "UnrecognizedChainIdError";
  }
  return error;
};

// Ethereum Utils

/**
 * @returns current chain id as hex string
 */
export const getEthereumChainIdAsHex = () => {
  return ethereum?.chainId;
};
/**
 * @returns current chain id as number
 */
export const getEthereumChainId = () => {
  return ethereum?.chainId ? hexToNumber(ethereum.chainId) : undefined;
};

/**
 * Requests to switch into given chain in metamask
 * @param chainId chain id to switch
 */
export const switchEthereumChain = async (chainId: CHAIN_ID_HEX = CHAIN_ID_HEX.ETHER_CHAIN_ID) => {
  try {
    if (ethereum && getEthereumChainIdAsHex() !== chainId) {
      // it returns null even if request suceeded
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });

      return true;
    }
  } catch (switchError) {
    const error = convertMetamaskError(switchError);
    if (error === "UserRejectedError") {
      return false;
    }
    return undefined;
  }
};

// Ethers.js Utils

/**
 * get current address in provider via ethers.js
 * @returns address
 */
export const getAddressByMetamask = async (onError: (message: string) => void) => {
  try {
    if (!WEB3_PROVIDER) {
      openMetamaskLink(onError);
      return;
    }
    const address = await WEB3_PROVIDER.getSigner().getAddress();
    return address;
  } catch (error) {
    return undefined;
  }
};

/**
 * get user signature with current provider directly by ethers.js
 * @param nonce message (given nonce) to sign
 * @returns signature with given message
 */
export const getSignByMetamask = async (
  nonce: string,
  onError: (message: string) => void,
  t?: TFunction<Namespace<"contract">, undefined>,
) => {
  try {
    if (!WEB3_PROVIDER) {
      openMetamaskLink(onError);
      return;
    }
    return await WEB3_PROVIDER.getSigner().signMessage(nonce);
  } catch (signError) {
    const error = convertMetamaskError(signError);
    if (error === "UserRejectedError") {
      onError(t ? t("contract:common.reject") : "User rejected to sign");
    } else {
      onError(t ? t("contract:common.error") : "Cannot get sign");
    }
    return undefined;
  }
};

/**
 * transfer ether (or matic) to given address
 * @param to address that will be transferred to
 * @param value value to transfer. unit: ether
 * @returns
 */
export const transferValueByMetamask = async ({
  signer,
  to,
  value,
}: {
  signer: JsonRpcSigner;
  to: string;
  value: string;
}) => {
  try {
    return await signer.sendTransaction({
      to,
      value: ethers.utils.parseEther(value),
    });
  } catch (error) {
    return undefined;
  }
};

/**
 * open Metamask download site
 */
export const openMetamaskLink = (onError: (message: string) => void) => {
  onError("Install Metamask.");
  window.open("https://metamask.io/download/");
};
