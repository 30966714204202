import React from "react";
import classnames from "classnames/bind";
import Slider from "react-slick";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { NextArrow } from "@/components/slick/next-arrow";
import { PrevArrow } from "@/components/slick/prev-arrow";
import styles from "./index.module.scss";

const cx = classnames.bind(styles);

export const Wrapper: React.FC = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <Slider
        {...{
          className: cx("mobile_info"),
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          nextArrow: <NextArrow width="0.7rem" height="1.2rem" cx={cx} />,
          prevArrow: <PrevArrow width="0.7rem" height="1.2rem" cx={cx} />,
        }}
      >
        {children}
      </Slider>
    );
  return <div className={cx("info")}>{children}</div>;
};
