import { RichTextItemResponse } from "@/types/notion/common";
import styles from "./text.module.scss";

interface Props {
  text: RichTextItemResponse[];
  className?: string;
}

export const Text = ({ text, className }: Props) => {
  return (
    <>
      {text?.map((value, i) => {
        const {
          annotations: { bold, code, color, italic, strikethrough, underline },
          text,
        } = value;
        return (
          <span
            key={i}
            className={[
              bold ? styles.bold : "",
              code ? styles.code : "",
              italic ? styles.italic : "",
              strikethrough ? styles.strikethrough : "",
              underline ? styles.underline : "",
              className,
            ]
              .join(" ")
              .trim()}
            style={color !== "default" ? { color } : {}}
          >
            {text.link ? <a href={text.link.url}>{text.content}</a> : text.content}
          </span>
        );
      })}
    </>
  );
};
