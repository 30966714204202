import React from "react";
import classnames from "classnames/bind";

import { useQuery } from "react-query";
import { useRouter } from "next/router";
import { fetchFaq } from "@/services/notion";
import Block from "./block";

import styles from "./faq.module.scss";

const cx = classnames.bind(styles);

export const FAQSection = () => {
  const router = useRouter();

  const { data, isLoading, error } = useQuery("faqs", () => fetchFaq(router.locale));

  if (!data || isLoading || error) return null;

  return (
    <div className={cx("faq")} id="faq">
      <div className={cx("title")}>
        <span className={cx("bar")}>|</span> FAQ <span className={cx("bar")}>|</span>
      </div>
      <div className={cx("faqContainer")}>
        {data
          .filter((faq) => faq.properties.answer.rich_text?.length)
          .map((faq, index) => {
            return <Block key={index} faq={faq} />;
          })}
      </div>
    </div>
  );
};
