import { JsonRpcSigner } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import { CHAIN_ID } from "@syl-codes/modules/contracts";

export const INJECTED_CONNECTOR = new InjectedConnector({
  supportedChainIds: [CHAIN_ID.ETHER_CHAIN_ID, CHAIN_ID.GOERLI_CHAIN_ID],
});

export const PUBLIC_WALLET_CONNECT_CONNECTOR = new WalletConnectConnector({
  rpc: {
    1: `https://rpc.ankr.com/eth`,
  },
  qrcode: true,
});

/**
 * new WalletConnectConnector를 만들어주는 함수
 * @param infuraId
 * @returns WALLET_CONNECT
 */
export const generateWalletConnectConnector = (infuraId: string) => {
  return new WalletConnectConnector({
    rpc: {
      1: `https://mainnet.infura.io/v3/${infuraId}`,
    },
    qrcode: true,
  });
};

/**
 * get user signature with metamask by web3-react
 * @param signer provider signer to sign with (= ethers.js)
 * @param message message to sign
 * @returns signature with given message
 */
export const getSignByWeb3Library = async (signer: JsonRpcSigner | undefined, message: string) => {
  try {
    if (!signer) return undefined;
    return await signer.signMessage(message);
  } catch (error) {
    return undefined;
  }
};
