import React from "react";
import classnames from "classnames/bind";
import { useQuery } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Image from "next/image";
import { toast } from "react-toastify";
import { commaSplitNumber } from "@syl-codes/modules";
import { getInfo } from "@/services/info";
import CustomLink from "@/components/link";
import { MEDIA_LINK } from "@/constants/media";
import { ICON_COPY } from "@syl-codes/ui/svgs";
import styles from "./index.module.scss";
import { Wrapper } from "./wrapper";

const cx = classnames.bind(styles);

export const InfoSection = () => {
  const { data, isLoading, error } = useQuery("info", getInfo);

  if (!data || isLoading || error) return null;

  const { volume, owners, circulatingSupply, marketCap, burnedSylCoin, discordMembers, twitterFollowers } = data;

  const handleClickCopy = () => {
    toast.success("Copied.", {
      position: "top-center",
      theme: "colored",
      autoClose: 1000,
    });
  };
  return (
    <Wrapper>
      <>
        <CustomLink href={MEDIA_LINK.OPENSEA_ETH} className={cx("block")}>
          VOLUME TRADED
          <span className={cx("value")}>
            <span className={cx("eth")}>
              <Image priority src="/eth.svg" width={20} height={20} />
            </span>
            {commaSplitNumber(volume.toFixed(1))}
          </span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.OPENSEA_ETH} className={cx("block")}>
          NFT OWNERS
          <span className={cx("value")}>{commaSplitNumber(owners)}</span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.DISCORD} className={cx("block")}>
          DISCORD MEMBERS
          <span className={cx("value")}>{commaSplitNumber(discordMembers)}</span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.TWITTER} className={cx("block")}>
          TWITTER FOLLOWERS
          <span className={cx("value")}>{commaSplitNumber(twitterFollowers)}</span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.UNISWAP_CHART} className={cx("block")}>
          MARKET CAP
          <span className={cx("value")}>${commaSplitNumber(marketCap)}</span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.UNISWAP_SWAP} className={cx("block")}>
          CIRCULATING SUPPLY
          <span className={cx("value")}>{commaSplitNumber(circulatingSupply)} SYL</span>
        </CustomLink>
      </>
      <>
        <CustomLink href={MEDIA_LINK.SYLTOKEN} className={cx("block")}>
          SYLTOKEN BURNED
          <span className={cx("value")}>{commaSplitNumber(burnedSylCoin)} SYL</span>
        </CustomLink>
      </>
      <CopyToClipboard text={"0x92925Acf2771Bc59143228499f9610FE5176eb9C"}>
        <div>
          <div onClick={handleClickCopy} className={cx("block")}>
            SYLTOKEN ADDRESS
            <span className={cx("value")}>
              0x92...eb9C <ICON_COPY className={cx("copy")} />
            </span>
          </div>
        </div>
      </CopyToClipboard>
    </Wrapper>
  );
};
