import { dehydrate, QueryClient } from "react-query";

import classnames from "classnames/bind";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import styles from "./index.module.scss";

import OpenSea from "@/components/opensea";
import { AboutSection } from "@/components/section/about";
import { FAQSection } from "@/components/section/faq";
import { InfoSection } from "@/components/section/info";
import { LogoSection } from "@/components/section/logo";
import { MilestoneSection } from "@/components/section/milestone";
import { TeamSection } from "@/components/section/team";
import { getInfo } from "@/services/info";
import { fetchFaq, fetchMilestone, fetchNewStory } from "@/services/notion";

const cx = classnames.bind(styles);

export default function Home() {
  return (
    <div className={cx("container")}>
      <InfoSection />
      <main className={cx("main")}>
        <LogoSection />
        <AboutSection />
        <MilestoneSection />
        {/* <TeamSection /> */}
        <FAQSection />
      </main>
      <OpenSea />
      <style global jsx>{`
        body {
          background: #edf3f9;
        }
      `}</style>
    </div>
  );
}

export const getStaticProps: GetStaticProps = async ({ locale = "en" }) => {
  const queryClient = new QueryClient();

  await Promise.all([
    queryClient.prefetchQuery("faqs", () => fetchFaq(locale)),
    queryClient.prefetchQuery("milestone", () => fetchMilestone(locale)),
    queryClient.prefetchQuery("story", () => fetchNewStory(locale)),
    queryClient.prefetchQuery("info", getInfo),
  ]);

  return {
    props: {
      ...(await serverSideTranslations(locale, ["home", "common", "footer"])),
      dehydratedState: dehydrate(queryClient),
    },
    revalidate: 5,
  };
};
