import classnames from "classnames/bind";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import CardList from "@/components/card-list";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import styles from "./index.module.scss";
import { Wrapper } from "./wrapper";

const cx = classnames.bind(styles);

export const AboutSection = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation("home");
  return (
    <div className={cx("wrapper")}>
      <CardList />
      <div className={cx("about")}>
        {isMobile && (
          <div className={cx("title")}>
            <span className={cx("bar")}>|</span> ABOUT SYL CARDS <span className={cx("bar")}>|</span>
          </div>
        )}
        <div className={cx("card_img")}>
          <Image src="/card.png" layout="fill" />
        </div>

        <div>
          {!isMobile && (
            <div className={cx("title_wrapper")}>
              <div className={cx("title")}>
                <span className={cx("bar")}>|</span> ABOUT SYL CARDS <span className={cx("bar")}>|</span>
              </div>
            </div>
          )}

          <Wrapper>
            <div className={cx("block")}>
              <div className={cx("grid_title")}>COLLECT</div>
              <div className={cx("description")}>{t("about-collect")}</div>
            </div>
            <div className={cx("block")}>
              <div className={cx("grid_title")}>EARN</div>
              <div className={cx("description")}>{t("about-earn")}</div>
            </div>
            <div className={cx("block")}>
              <div className={cx("grid_title")}>PLAY</div>
              <div className={cx("description")}>{t("about-play")}</div>
            </div>
            <div className={cx("block")}>
              <div className={cx("grid_title")}>TRADE</div>
              <div className={cx("description")}>{t("about-trade")}</div>
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};
