import * as NOTION_ID from "@/constants/notion";
import { FAQ } from "@/types/notion/faq";
import { Milestone } from "@/types/notion/milestone";
import { Story } from "@/types/notion/story";
import { getDatabase } from "@syl-codes/modules/assets/notion.utils";

const getNotionId = (type: "FAQ" | "MILESTONE" | "STORY", locale: string) => {
  switch (true) {
    case type === "FAQ" && locale === "ko":
      return NOTION_ID.FAQ_ID_KR;
    case type === "MILESTONE" && locale === "ko":
      return NOTION_ID.MILESTONE_ID_KR;
    case type === "STORY" && locale === "ko":
      return NOTION_ID.STORY_ID_KR;

    case type === "FAQ" && locale === "en":
      return NOTION_ID.FAQ_ID_EN;
    case type === "MILESTONE" && locale === "en":
      return NOTION_ID.MILESTONE_ID_EN;
    case type === "STORY" && locale === "en":
      return NOTION_ID.STORY_ID_EN;

    case type === "FAQ" && locale === "vi":
      return NOTION_ID.FAQ_ID_VI;
    case type === "MILESTONE" && locale === "vi":
      return NOTION_ID.MILESTONE_ID_VI;
    case type === "STORY" && locale === "vi":
      return NOTION_ID.STORY_ID_VI;

    default:
      return "";
  }
};

export const fetchFaq = (locale = "en"): Promise<FAQ[]> =>
  getDatabase(getNotionId("FAQ", locale), {
    sorts: [
      {
        property: "question",
        direction: "descending",
      },
    ],
  }).then((list: any[]) => list.map(({ properties, id }) => ({ properties, id })));

export const fetchMilestone = (locale = "en"): Promise<Milestone[]> =>
  getDatabase(getNotionId("MILESTONE", locale), {
    sorts: [
      {
        property: "quarter",
        direction: "descending",
      },
      {
        property: "category",
        direction: "ascending",
      },
    ],
  }).then((list: any[]) => list.map(({ properties, id }) => ({ properties, id })));

export const fetchStory = (locale = "en"): Promise<Story[]> =>
  getDatabase(getNotionId("STORY", locale)).then((list: any[]) =>
    list.map(({ properties, id }) => ({ properties, id })),
  );

export const fetchNewStory = (locale = "en"): Promise<Story[]> =>
  getDatabase(getNotionId("STORY", locale), {
    filter: {
      and: [
        {
          property: "new",
          checkbox: {
            equals: true,
          },
        },
      ],
    },
  }).then((list: any[]) => list.map(({ properties, id }) => ({ properties, id })));

export const fetchSingleStory = ({ locale = "en", id }: { locale: string | undefined; id: number }): Promise<Story[]> =>
  getDatabase(getNotionId("STORY", locale), {
    filter: {
      and: [
        {
          property: "id",
          number: {
            equals: id,
          },
        },
      ],
    },
  }).then((list: any[]) => list.map(({ properties, id }) => ({ properties, id })));
