export const offset = <T>(arr: Array<T>, offset: number) => [...arr.slice(offset), ...arr.slice(0, offset)];

export const groupBy = <T>(arr: T[], fn: (item: T) => string) =>
  arr.map(fn).reduce((acc, val, i) => {
    acc[val] = (acc[val] || []).concat(arr[i]);
    return acc;
  }, {} as { [key: string]: T[] });

export const initializeArrayWithRange = (end: number, start = 0, step = 1) =>
  Array.from({ length: Math.ceil((end - start + 1) / step) }, (_, i) => i * step + start);

export const remove = <T>(arr: T | T[] | undefined, func: (value: T, index: number, array: T[]) => void) =>
  Array.isArray(arr)
    ? arr.filter(func).reduce((acc, val) => {
        arr.splice(arr.indexOf(val), 1);
        return acc.concat(val);
      }, [] as T[])
    : [];

export const mixupArray = <T>(arr: T[]) => {
  return arr.sort(() => Math.random() - 0.5);
};

export const divideArray = <T>(arr: T[], n: number) => {
  const currentArr = arr.slice();
  const length = currentArr.length;
  const divide = Math.floor(length / n) + (Math.floor(length % n) > 0 ? 1 : 0);
  const newArr = [] as T[][];

  for (let i = 0; i < divide; i++) {
    newArr.push(currentArr.splice(0, n));
  }

  return newArr;
};
