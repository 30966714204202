import React from "react";
import classnames from "classnames/bind";
import { ICON_CHECK } from "@syl-codes/ui/svgs";
import { Text } from "@/components/text";
import { MileStoneProperty } from "@/types/notion/milestone";
import styles from "./index.module.scss";

interface Props extends MileStoneProperty {
  overlap: boolean;
}

const cx = classnames.bind(styles);

const Stone = ({ category, description, title, done, overlap }: Props) => {
  return (
    <div className={cx("stoneContainer", { overlap })}>
      <div className={cx("categoryTitle")}>{overlap ? "" : category.select.name}</div>
      <div className={cx("stones")}>
        <div className={cx("stone")}>
          <div className={cx("milestoneTitle")}>
            <Text text={title.title} />
            {done.checkbox && <ICON_CHECK fill="#ea3f27" className={cx("checkIcon")} />}
          </div>
          <Text text={description.rich_text} />
        </div>
      </div>
    </div>
  );
};

export default Stone;
