import React from "react";
import classnames from "classnames/bind";
import Slider from "react-slick";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import styles from "./index.module.scss";

const cx = classnames.bind(styles);

export const Wrapper: React.FC = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile)
    return (
      <Slider
        {...{
          className: cx("mobile_wrapper"),
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dotsClass: cx("slick-dots"),
        }}
      >
        {children}
      </Slider>
    );
  return <div className={cx("grid")}>{children}</div>;
};
