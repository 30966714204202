import { Argument } from "classnames";
import { ICON_ARROW_RIGHT_THIN } from "@syl-codes/ui/svgs";

interface Props {
  cx: (...args: Argument[]) => string;
  onClick?: () => void;
  width: string;
  height: string;
}

export const PrevArrow = ({ cx, onClick, width, height }: Props) => {
  return (
    <ICON_ARROW_RIGHT_THIN
      width={width}
      height={height}
      className={cx("prev_arrow")}
      onClick={onClick}
      fill="#C1CEE3"
    />
  );
};
