export const FAQ_ID_KR = "0abc0145097648168947951450dca27b";
export const MILESTONE_ID_KR = "e5d7d65bd0774ca3aa544d523c3288d6";
export const STORY_ID_KR = "19c489913cb34acbacad8285e96bb65f";

export const FAQ_ID_EN = "93ad02393b5c49f3a80a8dc4994aa420";
export const MILESTONE_ID_EN = "cf77c82ab6524b2ca06d30294e87b804";
export const STORY_ID_EN = "80128b9367c34a5db73f074c6b3d3d63";

export const FAQ_ID_VI = "e376c91fba074822997fcafbb3cff5f2";
export const MILESTONE_ID_VI = "1acb7c38beac4ff2ada7706ad2625eea";
export const STORY_ID_VI = "50f6fc5508db4dd7808f4f77a4f0a025";
