import { useState } from "react";
import classnames from "classnames/bind";
import Slider from "react-slick";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { NextArrow } from "@/components/slick/next-arrow";
import { PrevArrow } from "@/components/slick/prev-arrow";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { fetchNewStory } from "@/services/notion";
import styles from "./news.module.scss";
import { Tutorial } from "./tutorial";
import { Story } from "./story";

const cx = classnames.bind(styles);

export const News = () => {
  const [index, setIndex] = useState(0);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { data, isLoading, error } = useQuery("story", () => fetchNewStory(router.locale));

  if (!data || isLoading || error) return null;

  const beforeChange = (_prev: number, next: number) => {
    setIndex(next);
  };

  return (
    <div className={cx("wrapper")}>
      <Slider
        {...{
          className: cx("news"),
          dots: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: !isMobile,
          beforeChange: beforeChange,
          dotsClass: cx("slick-dots"),
          nextArrow: <NextArrow width="2rem" height="2rem" cx={cx} />,
          prevArrow: <PrevArrow width="2rem" height="2rem" cx={cx} />,
        }}
      >
        <>
          <Tutorial />
        </>

        {data.map((item, i) => (
          <div key={i}>
            <Story story={item} />
          </div>
        ))}
      </Slider>
      {index !== 0 && <div className={cx("label")}>NEW!</div>}
    </div>
  );
};
