import { ethers, BigNumber } from "ethers";

/**
 * hex string을 number로 변환
 * @param hex
 */
export const hexToNumber = (hex: string) => parseInt(hex, 16);

/**
 * num을 hex string으로 변환
 * @param num
 */
export const numberToHex = (num: number) => ethers.utils.hexValue(num);

/**
 * wei (10 ** 18) 단위를 ether 단위로 변환
 * @example `weiToEther("1000000000000000000")` = "1"
 */
export const weiToEther = (wei: string) => {
  if (wei == "0") {
    return "0";
  }
  const formatEtherString = ethers.utils.formatEther(wei);
  if (formatEtherString.endsWith(".0")) {
    return formatEtherString.replace(".0", "");
  }
  return formatEtherString;
};

/**
 * ether 단위를 wei (10 ** 18) 단위로 변환
 * @example `etherToWei("1").toString()` = "1000000000000000000"
 */
export const etherToWei = (ether: string | number) => ethers.utils.parseEther(ether.toString());

/**
 * wei 단위를 mwei (10 ** 6) 단위로 변환
 * @example `weiToMwei("1000000")` = "1"
 */
export const weiToMwei = (wei: string) => {
  if (wei == "0") {
    return "0";
  }
  const formatEtherString = ethers.utils.formatUnits(wei, "mwei");
  if (formatEtherString.endsWith(".0")) {
    return formatEtherString.replace(".0", "");
  }
  return formatEtherString;
};

/**
 * mwei (10 ** 6) 단위를 wei 단위로 변환
 * @example `mweiTowei("1").toString()` = "1000000"
 */
export const mweiToWei = (mwei: string | number) => ethers.utils.parseUnits(mwei.toString(), "mwei");

/**
 * wei 단위의 수를 human-readable한 단위로 변환
 *
 * SYL토큰의 경우 ether(10 ** 18) 단위로, USDC의 경우 mwei(10 ** 6) 단위로 변환되어야 함
 *
 * 이외 토큰이 사용되는 경우 추가 고려 필요
 */
export const weiToReadable = (wei: string, token?: "SYL" | "USDC") => {
  if (token === "USDC") {
    return weiToMwei(wei);
  }
  return weiToEther(wei);
};

/**
 * human-readable한 단위의 수를 wei 단위로 변환
 *
 * SYL토큰의 경우 ether(10 ** 18) 단위였고, USDC의 경우 mwei(10 ** 6) 단위였음
 *
 * 이외 토큰이 사용되는 경우 추가 고려 필요
 */
export const readableToWei = (amount: string | number, token?: "SYL" | "USDC") => {
  if (token === "USDC") {
    return mweiToWei(amount);
  }
  return etherToWei(amount);
};

/**
 * 특정 수(number 혹은 string 타입)를 BigNumber 타입으로 변환
 * @param num
 */
export const toBigNumber = (num: number | string) => {
  try {
    return BigNumber.from(num);
  } catch (error) {
    console.log(error);
    return BigNumber.from(0);
  }
};

/**
 * num이 정수인지 실수(소수)인지 반환
 */
export const isInteger = (num: string | number) => {
  if (isNaN(Number(num))) {
    return false;
  }
  if (typeof num === "string") {
    return !num.includes(".");
  }
  return num % 1 === 0;
};

/**
 * string을 받아 특정 소수점 자리까지 표시 (버림)
 * @param num string
 * @param digit 소수점 몇째 자리까지 표시할 것인지 (default 3)
 */
export const floorBigNumberString = (num: string, digit: number = 3) => {
  const numArray = num.split(".");
  const wholeNumber = numArray[0];
  if (numArray.length !== 2) {
    return wholeNumber;
  }
  const decimal = numArray[1];
  if (decimal === "0" || digit <= 0) {
    return wholeNumber;
  }
  if (decimal.length <= digit) {
    return `${wholeNumber}.${decimal}`;
  }
  return `${wholeNumber}.${decimal.slice(0, digit)}`;
};

export const shortenNumber = (num: number | string | undefined) => {
  if (!num) return 0.0;

  let unit = "";
  let shortenNumber: number = typeof num === "string" ? parseInt(num, 10) : num;

  if (num > 1000 * 1000) {
    shortenNumber = shortenNumber / (1000 * 1000);
    unit = "m";
  } else if (num > 1000) {
    shortenNumber = shortenNumber / 1000;
    unit = "k";
  }

  return shortenNumber.toFixed(1) + unit;
};
