import classNames from "classnames/bind";
import Image from "next/image";
import { motion } from "framer-motion";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import styles from "./index.module.scss";

const cx = classNames.bind(styles);

export default function CardList() {
  const isMobile = useIsMobile();

  const distance = isMobile ? 5.5 : 13.5;
  const yList = isMobile ? [2.5, 1, 0, 1, 2.5] : [4, 1, 0, 1, 4];

  return (
    <div className={cx("wrapper")}>
      {yList.map((y, i) => (
        <motion.div
          key={i}
          className={cx("image")}
          transition={{ type: "spring", duration: 2 }}
          viewport={{ once: true }}
          whileInView={{ translateX: `${distance * (i - 2)}rem`, translateY: `${y}rem` }}
        >
          <Image priority src={`/card${i + 1}.png`} layout="fill" />
        </motion.div>
      ))}
    </div>
  );
}
