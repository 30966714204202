import { floorBigNumberString } from "../number";

const regexForInt = (num: string) => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * 특정 숫자를 받아 comma로 나누어 출력
 * @param num target number to split with comma
 * @param digit 소수점 몇째 자리까지 표시할 것인지 (default 전체 표시)
 */
export const commaSplitNumber = (num: number | null | undefined | string, digit?: number) => {
  if (num === null || num === undefined || isNaN(Number(num))) {
    return "-";
  }
  let strNum = `${num}`;
  if (digit !== undefined) {
    strNum = floorBigNumberString(strNum, digit);
  }

  // const strNum = `${num}`;
  if (strNum.includes(".")) {
    // 소수부 존재
    const [wholeNumber, decimal] = strNum.split(".");
    return `${regexForInt(wholeNumber)}.${decimal}`;
  }
  // 정수부만 존재
  return regexForInt(strNum);
};
