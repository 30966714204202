import classnames from "classnames/bind";
import Image from "next/image";
import { Link } from "@/components";
import { ICON_ARROW_RIGHT } from "@syl-codes/ui/svgs";

import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { Text } from "@/components/text";
import { Story as StoryType } from "@/types/notion/story";
import styles from "./news.module.scss";

const cx = classnames.bind(styles);

interface Props {
  story: StoryType;
}

export const Story = ({ story }: Props) => {
  const isMobile = useIsMobile();

  const { title, sub_title2, id } = story.properties;

  return (
    <Link className={cx("story")} href={`/story/${id.number}`}>
      <div className={cx("yeonghee_img")}>
        <Image priority src={`https://assets.syltare.com/website/characters/${id.number}.png`} layout="fill" />
      </div>
      <div>
        <span className={cx("story_title")}>SYL STORY : {title.title[0].plain_text}</span>
        <Text className={cx("story_subtitle")} text={sub_title2.rich_text} />
      </div>
      <div className={cx("arrow")}>
        <div className={cx("inner")}></div>
        <div className={cx("arrow_img")}>
          <ICON_ARROW_RIGHT width={isMobile ? "16" : "11"} height={isMobile ? "16" : "11"} fill="white" />
        </div>
      </div>
    </Link>
  );
};
