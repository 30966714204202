import React, { useState } from "react";
import classnames from "classnames/bind";
import { ICON_ARROW } from "@syl-codes/ui/svgs";

import { Text } from "@/components/text";
import { FAQ } from "@/types/notion/faq";
import styles from "./block.module.scss";

interface Props {
  faq: FAQ;
}

const cx = classnames.bind(styles);

export const Block = ({ faq }: Props) => {
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  return (
    <>
      <div
        className={cx("questionBlock")}
        onClick={() => {
          setIsOpenAnswer(!isOpenAnswer);
        }}
      >
        <div>
          <div className={cx("highlight", "iconQ")}>Q</div>
          <Text className={cx("question")} text={faq.properties.question.title} />
        </div>
        <ICON_ARROW className={cx(isOpenAnswer ? "IconLess" : "IconMore")} width={40} height={40} fill="#EA3F27" />
      </div>
      <div className={cx("answerBlock", isOpenAnswer ? "open" : "")}>
        <Text className={cx("answer")} text={faq.properties.answer.rich_text} />
      </div>
    </>
  );
};

export default Block;
