import { useState } from "react";
import Image from "next/image";
import { useWindowScroll, useUpdateEffect } from "react-use";
import classnames from "classnames/bind";
import CustomLink from "@/components/link";
import { ICON_ARROW_RIGHT } from "@syl-codes/ui/svgs";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { MEDIA_LINK } from "@/constants/media";
import styles from "./index.module.scss";

const cx = classnames.bind(styles);

export default function OpenSea() {
  const { y } = useWindowScroll();
  const [isBottom, setIsBottom] = useState(false);
  const isMobile = useIsMobile();
  useUpdateEffect(() => {
    const footerHeight = 120; // TODO: 실제론 15.3rem
    setIsBottom(window.innerHeight + y + footerHeight >= document.body.scrollHeight);
  }, [y]);

  if (isMobile) return null;

  return (
    <div
      className={cx("opensea", "animate__animated", {
        animate__fadeOutRight: isBottom,
        animate__fadeInRight: !isBottom,
      })}
    >
      <CustomLink href={MEDIA_LINK.OPENSEA_ETH} className={cx("more")}>
        <div className={cx("opensea_img")}>
          <Image src="/opensea.svg" layout="fill" />
        </div>
        OpenSea
        <div className={cx("arrow_right")}>
          <ICON_ARROW_RIGHT width="16" height="16" />
        </div>
      </CustomLink>
      <CustomLink href={MEDIA_LINK.OPENSEA_ETH} className={cx("leesunshin_img")}>
        <Image src="/leesunshin.png" layout="fill" />
      </CustomLink>
    </div>
  );
}
