import React from "react";
import classnames from "classnames/bind";

import { Milestone } from "@/types/notion/milestone";
import styles from "./index.module.scss";
import Stone from "./stone";

interface Props {
  title: string;
  items: Milestone[];
}

const cx = classnames.bind(styles);

export const Quarter = ({ title, items }: Props) => {
  return (
    <div className={cx("quarter")}>
      <div className={cx("quarterTitle")}>{title}</div>
      <div>
        {items.map((item, i: number) => (
          <Stone
            key={item.id}
            {...item.properties}
            overlap={i > 0 && items[i - 1].properties.category.select.name === item.properties.category.select.name}
          />
        ))}
      </div>
    </div>
  );
};
