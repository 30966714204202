import { Client } from "@notionhq/client";
import { QueryDatabaseParameters, QueryDatabaseResponse } from "@notionhq/client/build/src/api-endpoints";
const notion = new Client({
  auth: process.env.NOTION_TOKEN,
});
export const getDatabase = async (
  databaseId: string,
  options?: Partial<QueryDatabaseParameters>,
): Promise<QueryDatabaseResponse["results"]> => {
  const response = await notion.databases.query({
    database_id: databaseId,
    ...options,
  });
  return response.results;
};

export const getPage = async (pageId: string) => {
  const response = await notion.pages.retrieve({ page_id: pageId });
  return response;
};

export const getBlocks = async (blockId: string) => {
  const response = await notion.blocks.children.list({
    block_id: blockId,
    page_size: 50,
  });
  return response.results;
};
