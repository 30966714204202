import classnames from "classnames/bind";

import { useTranslation } from "next-i18next";
import { Link } from "@/components";
import { ICON_PLAY_CIRCLE } from "@syl-codes/ui/svgs";

import { MEDIA_LINK } from "@/constants/media";
import styles from "./news.module.scss";

const cx = classnames.bind(styles);

export const Tutorial = () => {
  const { t } = useTranslation("home");
  return (
    <Link className={cx("tutorial")} href={MEDIA_LINK.TUTORIAL}>
      <div>
        <div className={cx("story_title")}>TUTORIAL</div>
        <div className={cx("story_subtitle")}>{t("tutorial-description")}</div>
      </div>
      <div className={cx("play")}>
        <ICON_PLAY_CIRCLE width="4.5rem" height="4.5rem" fill="#EA3F27" />
      </div>
    </Link>
  );
};
